import React from "react"
import { Link } from "gatsby"
import { Heading, Box, Text } from "@chakra-ui/core"

import { Button, Layout } from "../components"

const NotFoundPage = () => (
  <Layout>
    <Box textAlign="center" maxW="1600" mx="auto" px="4" my={{ base: 10, lg: 12 }}>
      <Heading as="h1" fontSize={{ base: "5xl", lg: "5.625rem" }} mb={{ base: 6, lg: 8 }}>
        Fehler 404
      </Heading>
      <Text mb={{ base: 10, lg: 12 }}>
        Wir haben den Faden verloren… was war das Thema? Hier geht leider es nicht weiter.
      </Text>
      <Button as={Link} to="/">
        Hier geht es zurück
      </Button>
    </Box>
  </Layout>
)

export default NotFoundPage
